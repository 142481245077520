<template>
  <v-card class="mx-auto" @click="isConfirmation()">
    <v-row align="center" justify="center">
      <v-overlay color="White" :absolute="absolute" :value="progressBar">
        <div class="text-center">
          <v-progress-circular :size="70" :width="7" color="blue" indeterminate>
          </v-progress-circular>
        </div>
      </v-overlay>
    </v-row>
    <v-img class="grey lighten-2" aspect-ratio="1" :src="getImage('/' + product.cover)">
    </v-img>
    <v-card-text
      length="10"
      style="height: 100px; overflow: hidden; color: black"
      class="hidden-sm-and-down"
    >
      {{ product.title }}</v-card-text
    >

    <div class="text-center">
      <v-dialog v-model="dialogCheckout" hide-overlay max-width="30%" max-height="30%">
        <v-card>
          <v-img :src="getImage('/' + product.cover)" class="red--text"> </v-img>
          <v-card-text class="hidden-sm-and-down">
            <v-simple-table dense>
              <tbody>
                <tr>
                  <td>Name:</td>
                  <td>{{ product.title }}</td>
                </tr>
                <tr>
                  <td>Stock:</td>
                  <td>{{ product.stock }} item</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
          <v-card-actions>
            <v-btn
              block
              color="#1b5e20"
              :disabled="product.stock == 0"
              @click="buy"
              class="white--text"
            >
              <v-icon color="white">mdi-cart-plus</v-icon>
              BUY
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "product-item",
  props: ["product"],
  data: () => ({
    dialogCheckout: false,
    product: {},
    promotions: [],
    progressBar: false,
  }),
  computed: {
    ...mapGetters({
      carts: "cart/carts",
      user: "auth/user",
      flatform: "flatform/flatform",
    }),
  },
  methods: {
    ...mapActions({
      addCart: "cart/add",
      setAlert: "alert/set",
      setFlatform: "flatform/set",
    }),
    isConfirmation() {
      this.progressBar = true;
      this.PromotionByproductId(this.product.id);
    },
    buy() {
      let formData = new FormData();
      formData.append("id", this.product.id);
      formData.append("title", this.product.title);
      formData.append("cover", this.product.cover);
      if (this.flatform == "TOKOPEDIA") {
        formData.append("price", this.product.price_product_tokopedia);
      } else if (this.flatform == "SHOPEE") {
        formData.append("price", this.product.price_product_shopee);
      } else if (this.flatform == "BUKALAPAK") {
        formData.append("price", this.product.price_product_bukalapak);
      } else if (this.flatform == "LAZADA") {
        formData.append("price", this.product.price_product_lazada);
      } else {
        formData.append("price", this.product.price);
      }

      formData.append("weight", this.product.weight);
      formData.append("quantity", this.product.quantity);
      formData.append("stock", this.product.stock);
      //add promosi item
      if (this.promotions != null) {
        formData.append("promotion_id", this.promotions.id);
        formData.append("typ_promotion_id", this.promotions.typ_promotion_id);
        formData.append("required", this.promotions.required);
        formData.append("result", this.promotions.result);
      }

      this.addCart(formData);
      this.setAlert({
        status: true,
        color: "success",
        text: "Added to cart",
      });
      this.$router.push("/pages/point-of-sale");
      this.dialogCheckout = false;
    },
    PromotionByproductId(keyword) {
      let config = {
        headers: {
          Authorization: "Bearer " + this.user.api_token,
        },
      };
      this.axios
        .get("/promotion/PromotionByproductId/" + keyword, config)
        .then((response) => {
          let { data } = response.data;
          this.promotions = data[0];
          this.dialogCheckout = true;
          this.progressBar = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
